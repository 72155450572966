<template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <v-card color="grey lighten-5">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="headline">
                Lourdes</v-card-title
              >

              <v-card-subtitle
                >Es una advocación mariana que se refiere a las dieciocho apariciones de la Madre María, que una niña de 14 años...
              </v-card-subtitle>
              <v-card-actions>
                <v-btn text color="indigo accent-4" @click="irArticulo2">
                  Leer artículo
                </v-btn>
              </v-card-actions>
            </div>

            <v-avatar class="ma-3 ultimos-articulos__imagen" size="100" tile>
              <v-img src="../../../public/lourdes_art.jpg"></v-img>
            </v-avatar>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card color="grey lighten-5">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="headline">
                Aquiétate
              </v-card-title>
              <v-card-subtitle
                >La mayoría de las personas, cuando tienen una crisis o se les presenta alguna situación, no consideran detenerse en...</v-card-subtitle
              >
              <v-card-actions>
                <v-btn text color="indigo accent-4" @click="irArticulo1">
                  Leer artículo
                </v-btn>
              </v-card-actions>
            </div>

            <v-avatar class="ma-3 ultimos-articulos__imagen" size="100" tile>
              <v-img src="../../../public/aquietate.png"></v-img>
            </v-avatar>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card color="grey lighten-5">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="headline">
                Bicipositiva</v-card-title
              >

              <v-card-subtitle
                >Andar en bicicleta es lo más parecido a volar. Es una actividad del Quinto Rayo Verde de la Vida y Curación...
                </v-card-subtitle
              >
              <v-card-actions>
                <v-btn text color="indigo accent-4" @click="irArticulo3">
                  Leer artículo
                </v-btn>
              </v-card-actions>
            </div>

            <v-avatar class="ma-3 ultimos-articulos__imagen" size="100" tile>
              <v-img src="../../../public/bicipositiva.jpg"></v-img>
            </v-avatar>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card color="indigo lighten-5">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div class="ultimos-articulos__llave-tonal">
              <v-card-title class="headline">
                Llave tonal de la semana</v-card-title
              >

              <v-card-subtitle class="pb-1"
                >Llave Tonal de la Amada Madre María</v-card-subtitle
              >
              <v-card-text class="pb-0"
                >"Ave Verum Corpus K 618", de Wolfgang Amadeus Mozart.</v-card-text
              >
              <v-card-actions class="ultimos-articulos__audio">
                <audio
                  src="../../assets/audio/07 AVE VERUM CORPUS K 618 - WOLFGANG AMADEUS MOZART - MADRE MARIA.mp3"
                  autoplay
                  controls
                ></audio>
              </v-card-actions>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "UltimosArticulos",
  data() {
    return {
      url: process.env.BASE_URL,
    };
  },
  methods: {
    irArticulo1() {
      this.$router.push("articulos/aquietate");
    },
    irArticulo2() {
      this.$router.push("articulos/lourdes");
    },
    irArticulo3() {
      this.$router.push("articulos/bicipositiva");
    },
  },
};
</script>
<style lang="scss">
@media (max-width: 700px) {
  .ultimos-articulos__imagen {
    display: none;
  }

  .ultimos-articulos__llave-tonal {
    max-width: 100%;
  }

  .ultimos-articulos__audio {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
  }
}
</style>
