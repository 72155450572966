<template>
  <v-card class="mx-auto mt-3" max-width="400">
    <v-img
      class="error--text align-end"
      src="../../../public/lourdes.jpg"
    >
    </v-img>
    <v-card-title>Virgen de Lourdes</v-card-title>
    <v-card-text class="pb-0">Advocación de la Madre María <br> Ser de Rayo Verde de la Curación <br> Patrona de los enfermos </v-card-text>

    <v-divider class="mx-4"></v-divider>
    <v-card-subtitle class="pb-0"> DECRETO DE LA SEMANA </v-card-subtitle>

    <v-card-text class="text--primary" >
      <div class="pt-3">
        “Amada Virgen de Lourdes, por siempre madre de mi corazón, cúbreme con tu Manto Verde de la Curación que cura y sana toda apariencia de enfermedad en mí y en toda la humanidad".
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "SerDeLuz",
};
</script>